(function (root, factory) {
    if (typeof module !== 'undefined' && module.exports) {
        if (typeof angular === 'undefined') { factory(require('angular')) } else { factory(angular) }
        module.exports = 'ngDialog'
    } else if (typeof define === 'function' && define.amd) { define(['angular'], factory) } else { factory(root.angular) }
}(this, function (angular) {
    'use strict'; var m = angular.module('ngDialog', []); var $el = angular.element; var isDef = angular.isDefined; var style = (document.body || document.documentElement).style; var animationEndSupport = isDef(style.animation) || isDef(style.WebkitAnimation) || isDef(style.MozAnimation) || isDef(style.MsAnimation) || isDef(style.OAnimation); var animationEndEvent = 'animationend webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend'; var focusableElementSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]'; var disabledAnimationClass = 'ngdialog-disabled-animation'; var forceElementsReload = { html: !1, body: !1 }; var scopes = {}; var openIdStack = []; var keydownIsBound = !1; m.provider('ngDialog', function () {
        var defaults = this.defaults = { className: 'ngdialog-theme-default', disableAnimation: !1, plain: !1, showClose: !0, closeByDocument: !0, closeByEscape: !0, closeByNavigation: !1, appendTo: !1, preCloseCallback: !1, overlay: !0, cache: !0, trapFocus: !0, preserveFocus: !0, ariaAuto: !0, ariaRole: null, ariaLabelledById: null, ariaLabelledBySelector: null, ariaDescribedById: null, ariaDescribedBySelector: null }; this.setForceHtmlReload = function (_useIt) { forceElementsReload.html = _useIt || !1 }; this.setForceBodyReload = function (_useIt) { forceElementsReload.body = _useIt || !1 }; this.setDefaults = function (newDefaults) { angular.extend(defaults, newDefaults) }; var globalID = 0, dialogsCount = 0, closeByDocumentHandler, defers = {}; this.$get = ['$document', '$templateCache', '$compile', '$q', '$http', '$rootScope', '$timeout', '$window', '$controller', '$injector', function ($document, $templateCache, $compile, $q, $http, $rootScope, $timeout, $window, $controller, $injector) {
            var $elements = []; var privateMethods = {
                onDocumentKeydown: function (event) { if (event.keyCode === 27) { publicMethods.close('$escape') } }, activate: function ($dialog) { var options = $dialog.data('$ngDialogOptions'); if (options.trapFocus) { $dialog.on('keydown', privateMethods.onTrapFocusKeydown); $elements.body.on('keydown', privateMethods.onTrapFocusKeydown) } }, deactivate: function ($dialog) { $dialog.off('keydown', privateMethods.onTrapFocusKeydown); $elements.body.off('keydown', privateMethods.onTrapFocusKeydown) }, deactivateAll: function (els) { angular.forEach(els, function (el) { var $dialog = angular.element(el); privateMethods.deactivate($dialog) }) }, setBodyPadding: function (width) { var originalBodyPadding = parseInt(($elements.body.css('padding-right') || 0), 10); $elements.body.css('padding-right', (originalBodyPadding + width) + 'px'); $elements.body.data('ng-dialog-original-padding', originalBodyPadding); $rootScope.$broadcast('ngDialog.setPadding', width) }, resetBodyPadding: function () {
                    var originalBodyPadding = $elements.body.data('ng-dialog-original-padding'); if (originalBodyPadding) { $elements.body.css('padding-right', originalBodyPadding + 'px') } else { $elements.body.css('padding-right', '') }
                    $rootScope.$broadcast('ngDialog.setPadding', 0)
                }, performCloseDialog: function ($dialog, value) {
                    var options = $dialog.data('$ngDialogOptions'); var id = $dialog.attr('id'); var scope = scopes[id]; if (!scope) { return }
                    if (typeof $window.Hammer !== 'undefined') { var hammerTime = scope.hammerTime; hammerTime.off('tap', closeByDocumentHandler); hammerTime.destroy && hammerTime.destroy(); delete scope.hammerTime } else { $dialog.unbind('click') }
                    if (dialogsCount === 1) { $elements.body.unbind('keydown', privateMethods.onDocumentKeydown) }
                    if (!$dialog.hasClass('ngdialog-closing')) { dialogsCount -= 1 }
                    var previousFocus = $dialog.data('$ngDialogPreviousFocus'); if (previousFocus && previousFocus.focus) { previousFocus.focus() }
                    $rootScope.$broadcast('ngDialog.closing', $dialog, value); dialogsCount = dialogsCount < 0 ? 0 : dialogsCount; if (animationEndSupport && !options.disableAnimation) { scope.$destroy(); $dialog.unbind(animationEndEvent).bind(animationEndEvent, function () { privateMethods.closeDialogElement($dialog, value) }).addClass('ngdialog-closing') } else { scope.$destroy(); privateMethods.closeDialogElement($dialog, value) }
                    if (defers[id]) { defers[id].resolve({ id: id, value: value, $dialog: $dialog, remainingDialogs: dialogsCount }); delete defers[id] }
                    if (scopes[id]) { delete scopes[id] }
                    openIdStack.splice(openIdStack.indexOf(id), 1); if (!openIdStack.length) { $elements.body.unbind('keydown', privateMethods.onDocumentKeydown); keydownIsBound = !1 }
                }, closeDialogElement: function ($dialog, value) {
                    $dialog.remove(); if (dialogsCount === 0) { $elements.html.removeClass('ngdialog-open'); $elements.body.removeClass('ngdialog-open'); privateMethods.resetBodyPadding() }
                    $rootScope.$broadcast('ngDialog.closed', $dialog, value)
                }, closeDialog: function ($dialog, value) { var preCloseCallback = $dialog.data('$ngDialogPreCloseCallback'); if (preCloseCallback && angular.isFunction(preCloseCallback)) { var preCloseCallbackResult = preCloseCallback.call($dialog, value); if (angular.isObject(preCloseCallbackResult)) { if (preCloseCallbackResult.closePromise) { preCloseCallbackResult.closePromise.then(function () { privateMethods.performCloseDialog($dialog, value) }) } else { preCloseCallbackResult.then(function () { privateMethods.performCloseDialog($dialog, value) }, function () { return }) } } else if (preCloseCallbackResult !== !1) { privateMethods.performCloseDialog($dialog, value) } } else { privateMethods.performCloseDialog($dialog, value) } }, onTrapFocusKeydown: function (ev) {
                    var el = angular.element(ev.currentTarget); var $dialog; if (el.hasClass('ngdialog')) { $dialog = el } else { $dialog = privateMethods.getActiveDialog(); if ($dialog === null) { return } }
                    var isTab = (ev.keyCode === 9); var backward = (ev.shiftKey === !0); if (isTab) { privateMethods.handleTab($dialog, ev, backward) }
                }, handleTab: function ($dialog, ev, backward) {
                    var focusableElements = privateMethods.getFocusableElements($dialog); if (focusableElements.length === 0) {
                        if (document.activeElement) { document.activeElement.blur() }
                        return
                    }
                    var currentFocus = document.activeElement; var focusIndex = Array.prototype.indexOf.call(focusableElements, currentFocus); var isFocusIndexUnknown = (focusIndex === -1); var isFirstElementFocused = (focusIndex === 0); var isLastElementFocused = (focusIndex === focusableElements.length - 1); var cancelEvent = !1; if (backward) { if (isFocusIndexUnknown || isFirstElementFocused) { focusableElements[focusableElements.length - 1].focus(); cancelEvent = !0 } } else { if (isFocusIndexUnknown || isLastElementFocused) { focusableElements[0].focus(); cancelEvent = !0 } }
                    if (cancelEvent) { ev.preventDefault(); ev.stopPropagation() }
                }, autoFocus: function ($dialog) {
                    var dialogEl = $dialog[0]; var autoFocusEl = dialogEl.querySelector('*[autofocus]'); if (autoFocusEl !== null) { autoFocusEl.focus(); if (document.activeElement === autoFocusEl) { return } }
                    var focusableElements = privateMethods.getFocusableElements($dialog); if (focusableElements.length > 0) { focusableElements[0].focus(); return }
                    var contentElements = privateMethods.filterVisibleElements(dialogEl.querySelectorAll('h1,h2,h3,h4,h5,h6,p,span')); if (contentElements.length > 0) { var contentElement = contentElements[0]; $el(contentElement).attr('tabindex', '-1').css('outline', '0'); contentElement.focus() }
                }, getFocusableElements: function ($dialog) { var dialogEl = $dialog[0]; var rawElements = dialogEl.querySelectorAll(focusableElementSelector); var tabbableElements = privateMethods.filterTabbableElements(rawElements); return privateMethods.filterVisibleElements(tabbableElements) }, filterTabbableElements: function (els) {
                    var tabbableFocusableElements = []; for (var i = 0; i < els.length; i++) { var el = els[i]; if ($el(el).attr('tabindex') !== '-1') { tabbableFocusableElements.push(el) } }
                    return tabbableFocusableElements
                }, filterVisibleElements: function (els) {
                    var visibleFocusableElements = []; for (var i = 0; i < els.length; i++) { var el = els[i]; if (el.offsetWidth > 0 || el.offsetHeight > 0) { visibleFocusableElements.push(el) } }
                    return visibleFocusableElements
                }, getActiveDialog: function () {
                    var dialogs = document.querySelectorAll('.ngdialog'); if (dialogs.length === 0) { return null }
                    return $el(dialogs[dialogs.length - 1])
                }, applyAriaAttributes: function ($dialog, options) {
                    if (options.ariaAuto) {
                        if (!options.ariaRole) { var detectedRole = (privateMethods.getFocusableElements($dialog).length > 0) ? 'dialog' : 'alertdialog'; options.ariaRole = detectedRole }
                        if (!options.ariaLabelledBySelector) { options.ariaLabelledBySelector = 'h1,h2,h3,h4,h5,h6' }
                        if (!options.ariaDescribedBySelector) { options.ariaDescribedBySelector = 'article,section,p' }
                    }
                    if (options.ariaRole) { $dialog.attr('role', options.ariaRole) }
                    privateMethods.applyAriaAttribute($dialog, 'aria-labelledby', options.ariaLabelledById, options.ariaLabelledBySelector); privateMethods.applyAriaAttribute($dialog, 'aria-describedby', options.ariaDescribedById, options.ariaDescribedBySelector)
                }, applyAriaAttribute: function ($dialog, attr, id, selector) {
                    if (id) { $dialog.attr(attr, id) }
                    if (selector) {
                        var dialogId = $dialog.attr('id'); var firstMatch = $dialog[0].querySelector(selector); if (!firstMatch) { return }
                        var generatedId = dialogId + '-' + attr; $el(firstMatch).attr('id', generatedId); $dialog.attr(attr, generatedId); return generatedId
                    }
                }, detectUIRouter: function () { try { angular.module('ui.router'); return !0 } catch (err) { return !1 } }, getRouterLocationEventName: function () {
                    if (privateMethods.detectUIRouter()) { return '$stateChangeSuccess' }
                    return '$locationChangeSuccess'
                }
            }; var publicMethods = {
                __PRIVATE__: privateMethods, open: function (opts) {
                    var options = angular.copy(defaults); var localID = ++globalID; var dialogID = 'ngdialog' + localID; openIdStack.push(dialogID); opts = opts || {}; angular.extend(options, opts); var defer; defers[dialogID] = defer = $q.defer(); var scope; scopes[dialogID] = scope = angular.isObject(options.scope) ? options.scope.$new() : $rootScope.$new(); var $dialog, $dialogParent; var resolve = angular.extend({}, options.resolve); angular.forEach(resolve, function (value, key) { resolve[key] = angular.isString(value) ? $injector.get(value) : $injector.invoke(value, null, null, key) }); $q.all({ template: loadTemplate(options.template || options.templateUrl), locals: $q.all(resolve) }).then(function (setup) {
                        var template = setup.template, locals = setup.locals; if (options.showClose) { template += '<div id="btnclose" class="ngdialog-close"></div>' }
                        var hasOverlayClass = options.overlay ? '' : ' ngdialog-no-overlay'; $dialog = $el('<div id="ngdialog' + localID + '" class="ngdialog' + hasOverlayClass + '"></div>'); if (options.id == "mapidservice") { $dialog.html((options.overlay ? '<div class="ngdialog-overlay"></div><div class="ngdialog-content" role="document" style="width:500px!important;">' + template + '</div>' : '<div class="ngdialog-content" role="document" style="width:500px!important;">' + template + '</div>')) }
                        else if (options.id == "FlightStatusservice") { $dialog.html((options.overlay ? '<div class="ngdialog-overlay"></div><div class="ngdialog-content" role="document" style="width:30% !important;">' + template + '</div>' : '<div class="ngdialog-content" role="document" style="width:30% !important;">' + template + '</div>')) }
                        else { $dialog.html((options.overlay ? '<div class="ngdialog-overlay"></div><div class="ngdialog-content" role="document">' + template + '</div>' : '<div class="ngdialog-content" role="document">' + template + '</div>')) }
                        $dialog.data('$ngDialogOptions', options); scope.ngDialogId = dialogID; if (options.data && angular.isString(options.data)) { var firstLetter = options.data.replace(/^\s*/, '')[0]; scope.ngDialogData = (firstLetter === '{' || firstLetter === '[') ? angular.fromJson(options.data) : options.data; scope.ngDialogData.ngDialogId = dialogID } else if (options.data && angular.isObject(options.data)) { scope.ngDialogData = options.data; scope.ngDialogData.ngDialogId = dialogID }
                        if (options.className) { $dialog.addClass(options.className) }
                        if (options.disableAnimation) { $dialog.addClass(disabledAnimationClass) }
                        if (options.appendTo && angular.isString(options.appendTo)) { $dialogParent = angular.element(document.querySelector(options.appendTo)) } else { $dialogParent = $elements.body }
                        privateMethods.applyAriaAttributes($dialog, options); if (options.preCloseCallback) {
                            var preCloseCallback; if (angular.isFunction(options.preCloseCallback)) { preCloseCallback = options.preCloseCallback } else if (angular.isString(options.preCloseCallback)) { if (scope) { if (angular.isFunction(scope[options.preCloseCallback])) { preCloseCallback = scope[options.preCloseCallback] } else if (scope.$parent && angular.isFunction(scope.$parent[options.preCloseCallback])) { preCloseCallback = scope.$parent[options.preCloseCallback] } else if ($rootScope && angular.isFunction($rootScope[options.preCloseCallback])) { preCloseCallback = $rootScope[options.preCloseCallback] } } }
                            if (preCloseCallback) { $dialog.data('$ngDialogPreCloseCallback', preCloseCallback) }
                        }
                        scope.closeThisDialog = function (value) { privateMethods.closeDialog($dialog, value) }; if (options.controller && (angular.isString(options.controller) || angular.isArray(options.controller) || angular.isFunction(options.controller))) {
                            var label; if (options.controllerAs && angular.isString(options.controllerAs)) { label = options.controllerAs }
                            var controllerInstance = $controller(options.controller, angular.extend(locals, { $scope: scope, $element: $dialog }), !0, label); if (options.bindToController) { angular.extend(controllerInstance.instance, { ngDialogId: scope.ngDialogId, ngDialogData: scope.ngDialogData, closeThisDialog: scope.closeThisDialog }) }
                            $dialog.data('$ngDialogControllerController', controllerInstance())
                        }
                        $timeout(function () {
                            var $activeDialogs = document.querySelectorAll('.ngdialog'); privateMethods.deactivateAll($activeDialogs); $compile($dialog)(scope); var widthDiffs = $window.innerWidth - $elements.body.prop('clientWidth'); $elements.html.addClass('ngdialog-open'); $elements.body.addClass('ngdialog-open'); var scrollBarWidth = widthDiffs - ($window.innerWidth - $elements.body.prop('clientWidth')); if (scrollBarWidth > 0) { privateMethods.setBodyPadding(scrollBarWidth) }
                            $dialogParent.append($dialog); privateMethods.activate($dialog); if (options.trapFocus) { privateMethods.autoFocus($dialog) }
                            if (options.name) { $rootScope.$broadcast('ngDialog.opened', { dialog: $dialog, name: options.name }) } else { $rootScope.$broadcast('ngDialog.opened', $dialog) }
                        }); if (!keydownIsBound) { $elements.body.bind('keydown', privateMethods.onDocumentKeydown); keydownIsBound = !0 }
                        if (options.closeByNavigation) { var eventName = privateMethods.getRouterLocationEventName(); $rootScope.$on(eventName, function () { privateMethods.closeDialog($dialog) }) }
                        if (options.preserveFocus) { $dialog.data('$ngDialogPreviousFocus', document.activeElement) }
                        closeByDocumentHandler = function (event) { var isOverlay = options.closeByDocument ? $el(event.target).hasClass('ngdialog-overlay') : !1; var isCloseBtn = $el(event.target).hasClass('ngdialog-close'); if (isOverlay || isCloseBtn) { publicMethods.close($dialog.attr('id'), isCloseBtn ? '$closeButton' : '$document') } }; if (typeof $window.Hammer !== 'undefined') { var hammerTime = scope.hammerTime = $window.Hammer($dialog[0]); hammerTime.on('tap', closeByDocumentHandler) } else { $dialog.bind('click', closeByDocumentHandler) }
                        dialogsCount += 1; return publicMethods
                    }); return { id: dialogID, closePromise: defer.promise, close: function (value) { privateMethods.closeDialog($dialog, value) } }; function loadTemplateUrl(tmpl, config) { $rootScope.$broadcast('ngDialog.templateLoading', tmpl); return $http.get(tmpl, (config || {})).then(function (res) { $rootScope.$broadcast('ngDialog.templateLoaded', tmpl); return res.data || '' }) }
                    function loadTemplate(tmpl) {
                        if (!tmpl) { return 'Empty template' }
                        if (angular.isString(tmpl) && options.plain) { return tmpl }
                        if (typeof options.cache === 'boolean' && !options.cache) { return loadTemplateUrl(tmpl, { cache: !1 }) }
                        return loadTemplateUrl(tmpl, { cache: $templateCache })
                    }
                }, openConfirm: function (opts) {
                    var defer = $q.defer(); var options = angular.copy(defaults); opts = opts || {}; angular.extend(options, opts); options.scope = angular.isObject(options.scope) ? options.scope.$new() : $rootScope.$new(); options.scope.confirm = function (value) { defer.resolve(value); var $dialog = $el(document.getElementById(openResult.id)); privateMethods.performCloseDialog($dialog, value) }; var openResult = publicMethods.open(options); openResult.closePromise.then(function (data) {
                        if (data) { return defer.reject(data.value) }
                        return defer.reject()
                    }); return defer.promise
                }, isOpen: function (id) { var $dialog = $el(document.getElementById(id)); return $dialog.length > 0 }, close: function (id, value) {
                    var $dialog = $el(document.getElementById(id)); if ($dialog.length) { privateMethods.closeDialog($dialog, value) } else { if (id === '$escape') { var topDialogId = openIdStack[openIdStack.length - 1]; $dialog = $el(document.getElementById(topDialogId)); if ($dialog.data('$ngDialogOptions').closeByEscape) { privateMethods.closeDialog($dialog, '$escape') } } else { publicMethods.closeAll(value) } }
                    return publicMethods
                }, closeAll: function (value) { var $all = document.querySelectorAll('.ngdialog'); for (var i = $all.length - 1; i >= 0; i--) { var dialog = $all[i]; privateMethods.closeDialog($el(dialog), value) } }, getOpenDialogs: function () { return openIdStack }, getDefaults: function () { return defaults }
            }; angular.forEach(['html', 'body'], function (elementName) { $elements[elementName] = $document.find(elementName); if (forceElementsReload[elementName]) { var eventName = privateMethods.getRouterLocationEventName(); $rootScope.$on(eventName, function () { $elements[elementName] = $document.find(elementName) }) } }); return publicMethods
        }]
    }); m.directive('ngDialog', ['ngDialog', function (ngDialog) { return { restrict: 'A', scope: { ngDialogScope: '=' }, link: function (scope, elem, attrs) { elem.on('click', function (e) { e.preventDefault(); var ngDialogScope = angular.isDefined(scope.ngDialogScope) ? scope.ngDialogScope : 'noScope'; angular.isDefined(attrs.ngDialogClosePrevious) && ngDialog.close(attrs.ngDialogClosePrevious); var defaults = ngDialog.getDefaults(); ngDialog.open({ template: attrs.ngDialog, className: attrs.ngDialogClass || defaults.className, controller: attrs.ngDialogController, controllerAs: attrs.ngDialogControllerAs, bindToController: attrs.ngDialogBindToController, scope: ngDialogScope, data: attrs.ngDialogData, showClose: attrs.ngDialogShowClose === 'false' ? !1 : (attrs.ngDialogShowClose === 'true' ? !0 : defaults.showClose), closeByDocument: attrs.ngDialogCloseByDocument === 'false' ? !1 : (attrs.ngDialogCloseByDocument === 'true' ? !0 : defaults.closeByDocument), closeByEscape: attrs.ngDialogCloseByEscape === 'false' ? !1 : (attrs.ngDialogCloseByEscape === 'true' ? !0 : defaults.closeByEscape), overlay: attrs.ngDialogOverlay === 'false' ? !1 : (attrs.ngDialogOverlay === 'true' ? !0 : defaults.overlay), preCloseCallback: attrs.ngDialogPreCloseCallback || defaults.preCloseCallback }) }) } } }]); return m
}))