/*
 * angular-confirm
 * http://schlogen.github.io/angular-confirm/
 * Version: 1.1.0 - 2015-14-07
 * License: Apache
 */
angular.module('angular-confirm', ['ui.bootstrap'])
    .controller('ConfirmModalController', ['$scope', '$uibModalInstance', 'data', '$rootScope', '$http', function ($scope, $uibModalInstance, data, $rootScope, $http) {
      $scope.data = angular.copy(data);

      $scope.ok = function () {
          $uibModalInstance.close();
      };

      $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
      };
      
      function isEmpty(val) {
          return (val === undefined || val == null || val.length <= 0) ? true : false;
      }

  }])
  .value('$confirmModalDefaults', {
      template: '<div class="modal-header"><h3 class="modal-title">{{data.title}}</h3></div>' +
      '<div class="modal-body" id="confirmorderpopup"><div class="modal-body">{{data.text}}</div><div class="modal-body" style="padding-top: 0px;"><div style="margin-bottom: 15px;" ng-if="(data.PrefixMessage != undefined && data.PrefixMessage != null)">{{data.PrefixMessage}}</div><div ng-if="(data.Message != undefined && data.Message != null)" ng-class="data.Colour">{{data.Message}}</div></div></div>' +
      '<div class="modal-footer">' +
      '<button id="idorderconfirmbtn" class="btn btn-primary" ng-click="ok()">{{data.ok}}</button>' +
      '<button id="idordercancelbtn" class="btn btn-default" ng-show="data.showCancelBtn === false" ng-click="cancel()">{{data.cancel}}</button>' +
      '</div>',
      controller: 'ConfirmModalController',
      defaultLabels: {
          title: 'Confirm',
          ok: 'OK',
          cancel: 'Cancel',
          showCancelBtn: false
      }
  })
  .factory('$confirm', ['$modal', '$confirmModalDefaults', function ($modal, $confirmModalDefaults) {
      return function (data, settings) {
          settings = angular.extend($confirmModalDefaults, (settings || {}));

          data = angular.extend({}, settings.defaultLabels, data || {});

          if ('templateUrl' in settings && 'template' in settings) {
              delete settings.template;
          }

          settings.resolve = {
              data: function () {
                  return data;
              }
          };

          return $modal.open(settings).result;
      };
  }])
  .directive('confirm', ['$confirm', function ($confirm) {
      return {
          priority: 1,
          restrict: 'A',
          scope: {
              confirmIf: "=",
              ngClick: '&',
              confirm: '@',
              confirmSettings: "=",
              confirmTitle: '@',
              confirmOk: '@',
              confirmCancel: '@'
          },
          link: function (scope, element, attrs) {


              element.unbind("click").bind("click", function ($event) {

                  $event.preventDefault();

                  if (angular.isUndefined(scope.confirmIf) || scope.confirmIf) {

                      var data = { text: scope.confirm };
                      if (scope.confirmTitle) {
                          data.title = scope.confirmTitle;
                      }
                      if (scope.confirmOk) {
                          data.ok = scope.confirmOk;
                      }
                      if (scope.confirmCancel) {
                          data.cancel = scope.confirmCancel;
                      }
                      $confirm(data, scope.confirmSettings || {}).then(scope.ngClick);
                  } else {

                      scope.$apply(scope.ngClick);
                  }
              });

          }
      }
  }]);
